<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 bg-black toolbar-title">
        <div slot="start" class="back-images-btn" @click="goBack">
          <ion-icon class="text-white" slot="icon-only" :icon="chevronBackOutline"></ion-icon>
        </div>
        <ion-label class="ml-2 text-white">{{ $t('back') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-no-padding bg-black-100">
      <div>
        <div class="pager-number">
          <ion-icon class="ml-1" slot="start" :icon="imagesOutline"></ion-icon>
          <ion-text>{{ imageIndex + 1 + '/' + images.length }}</ion-text>
        </div>
        <ion-slides
          class="media-slides"
          mode="ios"
          pager="false"
          :options="swipeSlidesOpts"
          @ionSlideDidChange="updatePager"
          ref="mySlides"
        >
          <ion-slide class="items-slide" v-for="(media, index) in getMediaImagesVideo" :key="index">
            <div class="h-100vh d-flex justify-center align-center">
              <video-player
                v-if="media.type === 'video'"
                class="video-player vjs-big-play-centered"
                :key="slideImagesVideoKey"
                :src="media.url"
                :poster="media.thumbnailUrl"
                :controls="isShowControls"
                :fullscreen="fullScreenOptions"
                :autoplay="index === imageIndex"
                disablePictureInPicture
                preferFullWindow
                userActions.click
                userActions.doubleClick
                responsive
                nativeControlsForTouch
                nativeAudioTracks
                nativeVideoTracks
                :volume="0.7"
                :height="videoPlayerHeight"
                :width="videoPlayerWidth"
                @click="setControls(true)"
                @mounted="handleMounted"
                @ended="setControls(false)"
                :options="videoOptions"
              >
                <template v-slot="{ player, state }">
                  <div class="custom-player-controls d-flex align-center justify-center">
                    <ion-button class="play-area" fill="clear" @click="handlePlayVideo(player, state)">
                      <ion-img v-if="!state.playing" class="play-img" src="/assets/images/play-video.svg" />
                    </ion-button>
                  </div>
                  <div class="volume" @click="player.muted(!state.muted)">
                    <ion-icon
                      class="ml-1"
                      slot="start"
                      :icon="state.muted ? volumeMedium : volumeMute"
                    ></ion-icon>
                    <ion-text>{{ state.muted ? 'Unmute' : 'Mute' }}</ion-text>
                  </div>
                </template>
              </video-player>
              <v-zoomer v-else class="preview-image">
                <img :src="media.url" class="image-proof" />
              </v-zoomer>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="px-1 py-1 bg-black">
        <div class="row end px-1 my-2">
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronBackCircleOutline"
            @click="selectPreviousImage"
          ></ion-icon>
          <div class="fs-3 text-white" v-if="mediaVariantName?.length > 0">
            {{ mediaVariantName }}
          </div>
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronForwardCircleOutline"
            @click="selectNextImage"
          ></ion-icon>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide v-for="(item, index) in images" :key="index">
            <div class="img-cover" @click="selectedImage(item, index)">
              <div v-if="item.type === 'video'" class="video-thumbnail">
                <ion-img class="play-icon" src="/assets/images/play-video.svg" />
              </div>
              <ion-img
                class="small-img"
                :class="item.url === showSelectedImageVideos && imageIndex === index ? 'selected-image' : ''"
                :src="
                  item.type === 'video'
                    ? item.thumbnailUrl
                    : isHttpImages(item.url)
                    ? item.url
                    : getImage(item.url)
                "
              ></ion-img>
            </div>
          </ion-slide>
        </ion-slides>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { VideoPlayer } from '@videojs-player/vue';
import {
  chevronBackCircleOutline,
  chevronBackOutline,
  chevronForwardCircleOutline,
  imagesOutline,
  playCircleOutline,
  volumeMedium,
  volumeMute
} from 'ionicons/icons';
import 'video.js/dist/video-js.css';
import { computed, defineComponent, ref } from 'vue';
import VueZoomer from 'vue-zoomer';

export default defineComponent({
  components: {
    VZoomer: VueZoomer.Zoomer,
    VideoPlayer
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    imagePath: {
      type: String,
      default: ''
    },
    imageDefault: {
      type: String,
      default: ''
    }
  },

  emits: ['close-page'],
  mounted() {
    this.selectedImage(this.images[this.selectedIndex], this.selectedIndex);
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    // Remove the window resize event listener to prevent memory leaks
    window.removeEventListener('resize', this.updateScreenDimensions);
  },

  setup(props, { emit }) {
    const isTrackingLinkNotValid = ref(false);
    const thumbnailUrl = ref('');
    const showSelectedImageVideos = ref('');
    const media = ref('');
    const imageIndex = ref(0);
    const videoPlayerHeight = ref(0);
    const videoPlayerWidth = ref(0);
    const slideImagesVideoKey = ref(0);
    const mySlides = ref(null);
    const updateScreenDimensions = () => {
      const screenHeight = window.innerHeight;
      videoPlayerHeight.value = screenHeight < 762 ? screenHeight * (50 / 100) : screenHeight * (60 / 100);
      videoPlayerWidth.value = window.innerWidth;
    };
    const player = ref(null);
    const currentState = ref(null);
    const isShowControls = ref(false);
    const handlePlayVideo = (player, state) => {
      isShowControls.value = true;
      player.value = player;
      !state.playing ? player.play() : player.pause();
    };
    const handleMounted = (payload) => {
      player.value = payload.player;
      currentState.value = payload.state;
      isShowControls.value = currentState.value?.playing ? true : false;
    };

    const setControls = (params) => {
      isShowControls.value = params;
    };

    const getMediaImagesVideo = computed(() => {
      return checkMediaData();
    });

    const checkMediaData = () => {
      const mediaData = props.images;
      return mediaData;
    };

    const slideOpts = {
      initialSlide: props.selectedIndex,
      speed: 500,
      slidesPerView: 'auto'
    };
    const swipeSlidesOpts = {
      initialSlide: props.selectedIndex,
      speed: 300,
      slidesPerView: 1,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      }
    };
    const updatePager = (event) => {
      imageIndex.value = event.srcElement?.swiper?.activeIndex;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const isHttpImages = (imageUrl) => {
      const isReadyImage = imageUrl.startsWith('http://') || imageUrl.startsWith('https://');
      return isReadyImage;
    };
    const mediaVariantName = ref('');
    const getImage = (image) => {
      const imageurl = process.env.VUE_APP_AWS_S3_IMAGE;
      const imagepath = IMAGE_PATH.PRODUCT;
      const defaultExtension = imagepath.indexOf('category-images') > -1 ? '.svg' : '.png';
      const imageName = image.indexOf('.') > -1 ? image : `${image}${defaultExtension}`;

      return `${imageurl}/${imagepath}/${imageName}`;
    };
    const selectedImage = (selectedImg, index) => {
      incrementSlideImagesVideoKey();
      checkMediaData();
      setControls(false);
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      mediaVariantName.value = selectedImg.variantName;
      (showSelectedImageVideos.value = isHttpImages(selectedImg.url)
        ? selectedImg.url
        : getImage(selectedImg.url)),
        (media.value = selectedImg.type),
        (imageIndex.value = index);
      thumbnailUrl.value = isHttpImages(selectedImg.url)
        ? selectedImg.thumbnailUrl
        : getImage(selectedImg.url);
      mySlides.value.$el.slideTo(index);
    };

    const incrementSlideImagesVideoKey = () => {
      slideImagesVideoKey.value++;
    };

    const mediaType = computed(() => {
      return media.value;
    });

    const selectNextImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value + 1) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const selectPreviousImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value - 1 + props.images?.length) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const isZoomed = ref(false);
    const imageContent = ref(null);
    const zoomImage = (params) => {
      isZoomed.value = params;
    };
    const setImageToCenter = () => {
      if (imageContent.value) {
        const contentElement = imageContent.value.$el;
        const contentImageHeight = contentElement.clientHeight;
        const contentImageWidth = contentElement.clientWidth;

        // Calculate the center position
        const centerX = contentImageWidth / 2;
        const centerY = contentImageHeight / 2;

        // Scroll to the center position
        contentElement.scrollToPoint(centerX, centerY, 500);
      }
    };
    // default zoom transform is 1
    const zoomLevel = ref(1);
    const zoomingImage = () => {
      if (zoomLevel.value === 1) {
        setImageToCenter();
        // on first touch/click will zoom image and set transform to 2.3
        zoomLevel.value = 2.3;
        zoomImage(true);
      } else {
        // on second touch/click will set zoom image to deafult 1
        zoomLevel.value = 1;
        zoomImage(false);
      }
    };

    const goBack = () => {
      emit('close-page');
    };
    return {
      chevronBackOutline,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      playCircleOutline,
      imagesOutline,
      volumeMute,
      volumeMedium,
      isTrackingLinkNotValid,
      goBack,
      slideOpts,
      player,
      slideImagesVideoKey,
      incrementSlideImagesVideoKey,
      currentState,
      mySlides,
      handlePlayVideo,
      handleMounted,
      getMediaImagesVideo,
      setControls,
      mediaType,
      thumbnailUrl,
      videoPlayerHeight,
      videoPlayerWidth,
      updateScreenDimensions,
      mediaVariantName,
      selectedImage,
      showSelectedImageVideos,
      imageIndex,
      isZoomed,
      isShowControls,
      zoomLevel,
      imageContent,
      zoomImage,
      zoomingImage,
      setImageToCenter,
      selectNextImage,
      selectPreviousImage,
      isHttpImages,
      getImage,
      swipeSlidesOpts,
      updatePager,
      DEFAULT_PRODUCT_IMAGE,
      IMAGE_PATH
    };
  },

  data() {
    return {
      videoOptions: {
        controlBar: {
          children: [
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
            'liveDisplay',
            'remainingTimeDisplay',
            'fullscreenToggle'
          ]
        }
      },
      fullScreenOptions: { options: { navigationUI: 'show' } }
    };
  }
});
</script>
<style scoped>
.video-js .vjs-big-play-button {
  display: none !important;
}

.swiper-media {
  height: 100%;
  width: 100%;
}

.video-player {
  background-color: #000;
}
.video-thumbnail {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  color: #fff;
  text-align: center;
}
.play-icon {
  margin: 18px;
  width: 36px;
  height: 36px;
}
.bg-black-100 {
  background-color: #000000;
  --background: #000000;
}
.pager-number {
  z-index: 999;
  display: inline-flex;
  height: 28px;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  right: 16px;
  top: 76px;
  border-radius: 16px;
  background: rgba(33, 33, 33, 0.5);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.volume {
  z-index: 999;
  display: inline-flex;
  height: 28px;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  right: 16px;
  bottom: 38px;
  border-radius: 16px;
  background: rgba(33, 33, 33, 0.5);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.custom-player-controls {
  height: 100%;
  width: 100%;
}
.play-img {
  height: 72px;
  width: 72px;
}

.play-area {
  height: 110px;
  width: 110px;
  border-radius: 55px;
  --color: white;
}

.media-slides {
  height: 100vh; /* Set height to 100% of viewport height */
  width: 100vw;
}

.items-slide {
  height: 100%; /* Set height to 100% of parent container (ion-slides) */
  width: 100%; /* Set width to 100% of parent container (ion-slides) */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}
</style>
<style src="@/modules/shared/views/orders/order-detail/style.scss" lang="scss" scoped></style>
